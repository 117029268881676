import Head from 'next/head';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useTranslation } from 'next-i18next';
import React from 'react';
import DefaultLayout from '@/components/templates/default';
import { NextPageWithLayout } from './_app';
// import MainSection from '@/components/organisms/MainSection';
import dynamic from 'next/dynamic';
import FloatingButtons from '@/components/organisms/FloatingButtons';

export const getStaticProps = async ({ locale, res }) => {
  return {
    props: {
      ...(await serverSideTranslations(locale, ['main', 'common'])),
    },
  };
};

const Main: NextPageWithLayout = () => {
  const { t } = useTranslation('main');
  const MainSection = dynamic(
    () => import('src/components/organisms/MainSection'),
    {},
  );

  return (
    <>
      <Head>
        <title>{t('mainSection.meta.title')}</title>
        <meta
          name="description"
          content={t('mainSection.meta.description') as string}
        />
        <meta
          name="keywords"
          content={t('mainSection.meta.keywords') as string}
        />
        <meta name="author" content="Clover Games Corp." />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content={t('mainSection.meta.title') as string}
        />
        <meta property="og:site_name" content="LORD OF HEROES" />
        <meta property="og:url" content="https://www.lordofheroes.com/ko/" />
        <meta
          property="og:description"
          content={t('mainSection.meta.description') as string}
        />
        <meta
          property="og:image"
          content="https://www.lordofheroes.com/ko/assets/sns.jpg"
        />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="al:ios:url" content="clovergames-loh://app" />
        <meta property="al:ios:app_store_id" content="1496742556" />
        <meta property="al:ios:app_name" content="LORD OF HEROES" />
        <meta property="al:android:url" content="clovergames-loh://app" />
        <meta property="al:android:app_name" content="LORD OF HEROES" />
        <meta
          property="al:android:package"
          content="com.clovergames.lordofheroes"
        />
        <meta
          property="al:web:url"
          content="https://www.lordofheroes.com/ko/index.html"
        />
      </Head>

      <FloatingButtons />
      <MainSection />
    </>
  );
};

Main.getLayout = function getLayout(page: React.ReactElement) {
  return <DefaultLayout>{page}</DefaultLayout>;
};

export default Main;
