import Link from 'next/link';
import styled from 'styled-components';

export const Background = styled.div`
  width: 253px;
  height: 406px;
  position: absolute;
  z-index: 5;
  right: 0;
  top: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  ${({ theme }) => theme.mobile} {
    width: calc(253px * 0.6);
    height: calc(406px * 0.75);
  }
`;

export const Grid = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 0.75rem;
  position: absolute;
  top: -2rem;
`;

export const BlogLink = styled(Link)`
  width: 192px;
  height: 224px;
  position: relative;
  ${({ theme }) => theme.mobile} {
    width: calc(192px * 0.75);
    height: calc(224px * 0.75);
  }
`;

export const CouponLink = styled(Link)`
  width: 192px;
  height: 184px;
  position: relative;
  ${({ theme }) => theme.mobile} {
    width: calc(192px * 0.75);
    height: calc(184px * 0.75);
  }
`;
