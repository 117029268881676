import React from 'react';
import * as S from './style';
import Image from 'next/image';
import { useIsMobile } from '@/hooks/useIsDesktop';
import { useRouter } from 'next/router';

const FloatingButtons = () => {
  const router = useRouter();
  const isMobile = useIsMobile();
  return (
    <S.Background>
      {!isMobile && (
        <Image
          src={`${process.env.NEXT_PUBLIC_RESOURCES_URL}/images/home/navbar/floating_box.webp`}
          alt="Background image of coupon link, blog link's container"
          fill
          sizes="50vw"
          priority={true}
        />
      )}
      <S.Grid>
        <S.BlogLink
          href={`https://blog-${router.locale}.lordofheroes.com/`}
          target="_blank"
        >
          <Image
            src={`${process.env.NEXT_PUBLIC_RESOURCES_URL}/images/home/navbar/blog.webp`}
            alt="Link to LOH's blog image"
            fill
          />
        </S.BlogLink>
        <S.CouponLink
          href="https://www.coupon.lordofheroes.com/"
          target="_blank"
        >
          <Image
            src={`${process.env.NEXT_PUBLIC_RESOURCES_URL}/images/home/navbar/coupon.webp`}
            alt="Link to coupon site image"
            fill
          />
        </S.CouponLink>
      </S.Grid>
    </S.Background>
  );
};

export default FloatingButtons;
